exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-front-page-js": () => import("./../../../src/templates/frontPage.js" /* webpackChunkName: "component---src-templates-front-page-js" */),
  "component---src-templates-kontakt-js": () => import("./../../../src/templates/kontakt.js" /* webpackChunkName: "component---src-templates-kontakt-js" */),
  "component---src-templates-labor-js": () => import("./../../../src/templates/labor.js" /* webpackChunkName: "component---src-templates-labor-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-projekte-js": () => import("./../../../src/templates/projekte.js" /* webpackChunkName: "component---src-templates-projekte-js" */),
  "component---src-templates-search-results-js": () => import("./../../../src/templates/search-results.js" /* webpackChunkName: "component---src-templates-search-results-js" */),
  "component---src-templates-service-js": () => import("./../../../src/templates/service.js" /* webpackChunkName: "component---src-templates-service-js" */)
}

